import styles from '../../styles/Home.module.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import icon from '../../public/favicon.ico'
import { useRouter } from 'next/router';
import api from '../api/Apis'
import { useEffect, useState } from 'react';
import dynamic from 'next/dynamic';

const Home = dynamic(() => import('../pages/Components/Home/Home'), {
  ssr: true,
});

export default function Index({ setshowloader, setshowminicart, minCartAutoshipCheck, smartShipCheck, setcounts, setInnerLoader, innerLoader, subDomain, USER_DATA }) {
  const route = useRouter();
  const store = "us";
  const [homePageData, sethomePageData] = useState(null);
  useEffect(() => {
    setInnerLoader(true)
    document.body.classList.remove('bg-salmon');
    api.getHomepageContent(store).then(res => {
      if (res?.status === 200) {
        sethomePageData(res?.data)
        setTimeout(() => {
          setshowloader(false)
          setInnerLoader(false);
        }, 4000);
      }
      else {
        setInnerLoader(false)
      }
    })
  }, []);

  return (
    <div className={homePageData === null ? 'home-all-data' : ""} >
      <Home styles={styles} icon={icon} homePageData={homePageData} setshowloader={setshowloader} smartShipCheck={smartShipCheck} setInnerLoader={setInnerLoader}
        setshowminicart={setshowminicart} minCartAutoshipCheck={minCartAutoshipCheck} setcounts={setcounts} innerLoader={innerLoader} subDomain={subDomain}
        USER_DATA={USER_DATA}
      />
    </div>
  )
}
